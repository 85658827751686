import React from 'react'
// import { BiLogoWhatsappSquare, BiMailSend } from 'react-icons/bi'
import avtaar from '../../assets/avtaarimage/avtaar.png'
import classes from './SmallAbout.module.css'

const SmallAbout = () => {
  return (
    <>
    <div>
      <img src={avtaar} alt="Avatar" className={classes.avatar}/>
    </div>

    <div className="p-2 mb-3 bg-light text-dark rounded">
        <h4 className="fst-italic">About</h4>
        <p className="mb-0">Nand Kishore</p>
        <p className="mb-0">Salesforce 7X Certificates.</p>
        <p className="fw-bold"></p>
    </div>
    </>
  )
}

export default SmallAbout