import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    
    <div className="container pt-4">
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-0 my-0 border-top">
                   
            {/* <NavLink to="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
            <svg className="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"/></svg>
            </NavLink> */}
        
            <ul className="nav col-md-4 justify-content-end">
              <li className="nav-item"><NavLink to="/" className="nav-link px-2 text-black text-body-secondary">Home</NavLink></li>
              <li className="nav-item"><NavLink to="/blog" className="nav-link px-2 text-black text-body-secondary">Blog</NavLink></li>
              <li className="nav-item"><NavLink to="/success-story" className="nav-link px-2 text-black text-body-secondary">Success Story</NavLink></li>
            </ul>
        </footer>
    </div>
  )
}

export default Footer