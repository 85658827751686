import React from 'react'
import { NavLink, Link, } from 'react-router-dom'

const Navigation = () => {
  return (

    // <header className={classes.header}>
    //   <nav>
        
    //     <ul className={classes.list}>
    //       <li>
    //         <Link to="/" style={{color:"black"}}>
    //           <span className='navbar-brand fw-bolder fs-4' style={{borderBottomStyle: "double",
    //           borderTopStyle: "double"}}>BLOG</span>
    //         </Link>

    //       </li>
    //       <li>
    //         <NavLink to="/" className={({ isActive }) => isActive ? classes.active : undefined } end >
    //           Home
    //         </NavLink>
    //       </li>
    //       <li>
    //         <NavLink to="/blog" className={({ isActive }) => isActive ? classes.active : undefined}>
    //           Blog
    //         </NavLink>
    //       </li>
    //       <li>
    //         <NavLink to="/casestudy" className={({ isActive }) => isActive ? classes.active : undefined}>
    //           Case Study
    //         </NavLink>
    //       </li>
    //     </ul>
    //   </nav>
    // </header>

<nav className="nav p-3 border-bottom">
  <li className="nav-item" style={{listStyle:'none', paddingLeft: '28px'}}>
    <Link className="navbar-brand" to="/" style={{color:'black'}}>
      <span className='fw-bolder fs-4' style={{borderBottomStyle:'double',
      borderTopStyle:'double'}}>BLOG</span>
    </Link>
  </li>
  <li className="nav-item">
    <NavLink className="nav-link text-black" aria-current="page" to="/">Home</NavLink>
  </li>
  <li className="nav-item">
    <NavLink className="nav-link text-black" to="/blog">Blog</NavLink>
  </li>
  <li className="nav-item">
    <NavLink className="nav-link text-black" to="/success-story">Success Story</NavLink>
  </li>
</nav>

  );
}

export default Navigation