import React  from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import classes from './BlogView.module.css'


const BlogView = () => {
  
  let {slug} = useParams();

  const showBlogData = useSelector((state)=> state.blogs.data);
  const storeDetailData = []

  showBlogData.salesforce.map((value, index)=>{
    if(slug===value.slug){
      storeDetailData.push(value); 
    }
    return 0;
  })

  return (
    <>
            
      <div className={classes.container}>
      {/* {storeDetailData.map((data)=>(
        <iframe title='iframe' key={data.id} className={classes.responsiveiframe} src={process.env.PUBLIC_URL + `/${data.path}`}></iframe>
        
        ))} */}
      </div>
      
    </>
  )
}

export default BlogView
