import React from 'react'
import { useNavigate } from 'react-router-dom';

const NoDataFound = () => {

    const navigate = useNavigate();
    const backhandle=()=>{
        navigate(-1);
      }

  return (
    <div>
        <p className='text-center fs-5 fw-bold'>No data found !</p>
        <button type="button" className='text-primary mt-2' onClick={backhandle} >go back</button>
    </div>
  )
}

export default NoDataFound