import { createSlice } from '@reduxjs/toolkit';

const CASESTUDYDATA = {
    "cases":[
        {
            "id":1,
            "slug":"how-a-property-dealer-achieved-increased-sales-and-improved-customer-satisfaction-with-us",
            "title":"How a Property Dealer Achieved Increased Sales and Improved Customer Satisfaction with Us",
            "path":"success-story/how-a-property-dealer-achieved-increased-sales-and-improved-customer-satisfaction-with-us.htm",
            "problem":"Our client, a property dealer with a large portfolio of properties, was struggling to manage their customer interactions effectively. They were using a basic CRM system that didn't offer the features they needed to stay competitive in the market. They were also experiencing a high volume of customer complaints due to poor communication and slow response times."
        },
        {
            "id":2,
            "slug":"automating-water-purifier-company's-data-management-processes",
            "title":"Automating water purifier company's data management processes and billing processes using CRM for enhanced operations and customer experience",
            "path":"success-story/automating-water-purifier-company's-data-management-processes.html",
            "problem":"This case study delves into the success story of the Water Purifying Company's problem that has revolutionized the way water is purified and made safe for consumption. Water Purifying Company was founded with a clear mission: to provide safe and clean drinking water to communities across "
        },
        {
            "id":3,
            "slug":"enhancing-data-management-on-an-Online-clothing-retail-site",
            "title":"Enhancing data-management on an online clothing retail site",
            "path":"success-story/enhancing-data-management-on-an-Online-clothing-retail-site.html",
            "problem":"In this case study, we explore the implementation of Salesforce as a robust data management solution for an online clothing retail site.The company faced challenges in efficiently managing customer data, sales data, and inventory information."
        },
        {
            "id":4,
            "slug":"streamlining-case-management-and-optimizing-IT-service efficiency",
            "title":"Streamlining case management and optimizing IT service efficiency",
            "path":"success-story/streamlining-case-management-and-optimizing-it-service-efficiency.html",
            "problem":"IT Service company is a leading company that offers a wide range of technology solutions, comprehensive cybersecurity solutions, trusted guidance, communication and the network solutions to businesses across various industries and recognizes the need to enhance its case management processes"
        },
        {
            "id":5,
            "slug":"enhancing-customer-feedback-management-using-salesforce",
            "title":"Enhancing customer feedback management using salesforce",
            "path":"success-story/enhancing-customer-feedback-management-using-salesforce.html",
            "problem":"This case study explores a fictitious company, an online product website, which faced challenges in handling customer feedback through their website. Struggling with the overwhelming volume of customer data and a lack of effective management tools"
        },  
        {
            "id":6,
            "slug":" managing-leads-and-event-appointments-for-a-salon-client",
            "title":" Managing Leads and Event Appointments for a Salon Client",
            "path":"success-story/managing-leads-and-event-appointments-for-a-salon-client.html",
            "problem":"The client is a well-established salon providing a wide range of beauty and wellness services with a focus on providing exceptional customer experiences and personalized services, the salon aims to attract and retain a loyal customer base"
        },  
        {
            "id":7,
            "slug":" salesforce-implementation-for-a-leading-marketplace-client",
            "title":" Salesforce Implementation for a Leading Marketplace Client (A metal company)",
            "path":"success-story/salesforce-implementation-for-a-leading-marketplace-client.html",
            "problem":"In this case study, we will explore how a prominent player in the metal industry successfully utilized Salesforce to address its key challenges and revolutionize its marketplace operations. The client partnered with Salesforce to leverage its"
        },   
        
    ]
}

CASESTUDYDATA.cases.sort((a, b) => 
    b.id - a.id
)

const caseStudySlice = createSlice({
    name:"casestudy",
    initialState : {data: CASESTUDYDATA}, 
    
})

export const casestudyActions = caseStudySlice.actions

export default caseStudySlice;
