import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';

const UserstoryView = () => {

    let {slug} = useParams()
    const casestudyData = useSelector((state)=> state.casestudy.data);

    const storeCaseStudy = []

    casestudyData.cases.map((value, index)=>{
        if(slug===value.slug){
            storeCaseStudy.push(value); 
        }
        return 0;
      })

  return (
    <div className='container-fluid pt-1'>
        <div className='row'>
            <div className='col'>
                {/* <div className={classes.container}>
                    {storeCaseStudy.map((data)=>(
                        <iframe title='iframe' key={data.id} className={classes.responsiveiframe} src={process.env.PUBLIC_URL + `/${data.path}`}></iframe>
                    ))}
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default UserstoryView