import React from 'react'
import { useSelector } from 'react-redux'


const Userstory = () => {
    
    const casestudyData = useSelector((state)=> state.casestudy.data);
    const studyData = casestudyData.cases;
    
  return (
    <>
    <div className='container'>
        <div className='row pt-4'>
            <div className='col'>
                {studyData.map((data)=>(
                    <div className="card mb-4" key={data.id}>
                        <div className="card-body">
                            <h5 className="card-title">{data.title}</h5>
                            <p className="card-text">{data.problem.substring(0, 305)}....</p>
                            <a href={`/${data.path}`} className='btn btn-outline-dark'>Read project</a>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    </div>
    </>
  )
}

export default Userstory