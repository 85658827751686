import React from 'react'

const Archives = (props) => {

   
  return (
    <>
        <div className="p-2 list-group">
            <h4 className="fst-italic">Archives</h4>
            <ol className="list-unstyled mb-0">
                {props.blogData.map((data)=>(
                    <li key={data.id} className='mb-2'>
                      <a href={`/${data.path}`} className='nav-link text-primary'>{data.date}</a>
                    </li>

                ))}
            </ol>
        </div>

    </>
  )
}

export default Archives