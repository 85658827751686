import React, {useEffect} from 'react'
import { useNavigate, useHref, Link } from 'react-router-dom';


const ErrorPage = () => {
  let pageHandle = true;
  
  const href = useHref();
  const navigate = useNavigate();

  if(href==='/project-highlights' || href==='/project-highlight/salesforce-implementation-for-a-leading-marketplace-client.html' || href==='project-highlight/managing-leads-and-event-appointments-for-a-salon-client.html' || href==='/project-highlight/enhancing-customer-feedback-management-using-salesforce.html' || href==='/project-hightlight/streamlining-case-management-and-optimizing-it-service-efficiency.html' || href==='/project-highlight/enhancing-data-management-on-an-Online-clothing-retail-site.html' || href==="project-highlight/automating-water-purifier-company's-data-management-processes.html" || href==='/project-highlight/how-a-property-dealer-achieved-increased-sales-and-improved-customer-satisfaction-with-us.htm'){
    pageHandle=false;
  }
    
  useEffect(() => {
      if(href==='/project-highlights' || href==='/project-highlight/salesforce-implementation-for-a-leading-marketplace-client.html' || href==='project-highlight/managing-leads-and-event-appointments-for-a-salon-client.html' || href==='/project-highlight/enhancing-customer-feedback-management-using-salesforce.html' || href==='/project-hightlight/streamlining-case-management-and-optimizing-it-service-efficiency.html' || href==='/project-highlight/enhancing-data-management-on-an-Online-clothing-retail-site.html' || href==="project-highlight/automating-water-purifier-company's-data-management-processes.html" || href==='/project-highlight/how-a-property-dealer-achieved-increased-sales-and-improved-customer-satisfaction-with-us.htm'){
        navigate('/success-story');
      }
  }, [href, navigate]);
  
  return (
    <>
    {pageHandle === true && <div className='justify-content-center pt-5'>
      <p className='text-center'>
        <span className='fw-bold fs-1'>404 <br /> Page Not Found !</span>
        <br /><br />Click here to go to the blog - <Link to='/' >Go to Nand Blogs</Link> 
      </p>
    </div>
    }
    </>

  )
}

export default ErrorPage