import React from 'react'

const Card = (props) => {
  return (
    <>
      {props.blogData.map((data)=>(
        <div className="card mb-4" key={data.id}>
            <div className="row g-0">
          
                <div className="col-md-12">
                    <div className="card-body">
                    <h5 className="card-title">
                      <a href={`/${data.path}`} className='nav-link text-black'>{data.title}</a>
                    </h5>
                        <p className="card-text ">{data.description}</p>
                        <p className="card-text d-flex justify-content-between">
                          <small className="text-body-secondary">{data.date}</small>
                          <small>
                            <a href={`/${data.path}`} className='nav-link text-primary'>Read more..</a>  
                          </small></p>
                        
                    </div>
                    
                </div>
            </div>
        </div>
      ))}   
    </>
  )
}

export default Card