import { lazy, Suspense  } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage"
import Root from "./pages/Root";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Loading from "./components/main/Loading";
//import BlogList from "./components/blogs/BlogList";
import BlogView from "./components/blogs/BlogView"
import UserstoryPage from "./pages/UserstoryPage";
import UserstoryView from "./components/userstory/UserstoryView";

const BlogPage = lazy(() => import('./pages/BlogPage'));

const router = createBrowserRouter([
  {path:'/' , 
    element: <Root />,
    errorElement: <ErrorPage />, 
    children:[
      {index:true, element:<HomePage/>},
      {path:'blog', element:
        <Suspense fallback={<Loading />} >
          <BlogPage />
        </Suspense>  
      
      },
      // {path:'blog/blog-list', element:<BlogList />},
      // {path:'blog-view/:slug', element:<BlogView />},
      {path:'blog/blog-view/:slug', element:<BlogView />},
      // {path:'blog/blog-view/:slug', element:<BlogView />}
      {path:'success-story', element:
        <Suspense fallback={<Loading />} >
          <UserstoryPage />
        </Suspense>
      },

      {path:'success-story/view-case-study/:slug', element:
        <Suspense fallback={<Loading />} >
          <UserstoryView />
        </Suspense>
      }
      
    ],
  }


])

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
