import { Outlet } from "react-router-dom"
import Navigation from "../components/headerfooter/Navigation"
import Footer from "../components/headerfooter/Footer"

const RootLayout = () => {
  return (
    <div>
        <Navigation />

        <main>
            <Outlet />
        </main>

        <Footer />
    </div>
  )
}

export default RootLayout