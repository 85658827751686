import React , { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Card from './Card'
import Archives from './Archives'
import SmallAbout from './SmallAbout'
import NoDataFound from './NoDataFound'
import { useEffect } from 'react'

const Homes = () => {
  
    const [isCheck, setIsCheck] = useState(false);

    const showBlogData = useSelector((state)=> state.blogs.data);
    const blogData = showBlogData.salesforce;

    useEffect(()=>{
      if(blogData !== undefined || blogData !== null){
        setIsCheck(true)
      }else {
        setIsCheck(false);
        return ;
      }
    }, [blogData])
    

  return (
    <>
      <div className="container pt-5">
          <div className="p-4 p-md-5 mb-4 rounded text-bg-dark">
              <div className="col-md-6 px-0">
                <h1 className="display-5 fst-italic">Best Practices, Tips, and Strategies for Success</h1>
                <p className="lead my-3">Unlocking Success with Salesforce: Expert Insights, Best Practices, and Strategies for Maximizing Your Sales and Development Efforts</p>
                <p className="lead mb-0"><Link to='/blog' className="text-white fw-bold">Continue reading...</Link></p>
              </div>
          </div>
      </div>

      <div className="container-fluid ">
          <div className="row p-2">
              <div className="col-lg-9 col-md-9 col-sm-12 ">
                  <h3 className="pb-4 mb-4 fst-italic border-bottom"><Link to='/blog' className='text-black'>Blogs</Link></h3>
                  <Card blogData={blogData}/>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12">
                <div>
                  <SmallAbout/>
                  {isCheck ? <Archives blogData={blogData} /> : <NoDataFound /> }
                </div>
              </div>
          </div>
      </div>

    </>
  )
}

export default Homes